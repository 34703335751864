.container {
  margin: auto;
  display: flex;
  height: 100vh;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  text-align: center;
}

h1 {
  font-size: 102px;
  color: rgb(255, 255, 255);
}

h2 {
  font-size: 52px;
  line-height: 1px;
  display: inline-block;/* or inline-block */
  text-overflow: ellipsis;
  word-wrap: break-word;
  color: rgb(255, 255, 255);
}

img {
  margin-top: 15px;
  width: 250px;
  height: 250px;
}

@media screen and (max-width: 992px) {
  h1 {
    font-size: 36px;
    margin-top: -160px;
  }

  h2 {
    font-size: 28px;
    margin-top: -160px;
  }

  img {
    height: 150px;
    width: 150px;
  }

}
