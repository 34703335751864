/* @import url('https://fonts.googleapis.com/css?family=Open+Sans:400,700&display=swap'); */

@font-face {
  font-family: 'schulbuchnord-fett';
  src: url('fonts/schulbuchnord-fett.ttf');
  font-weight: normal;
  font-style: normal;
}

html, body {
  /* background-color: #e89061; */
  background-color: rgb(0, 0, 0);
  margin: 0;
  font-family: 'schulbuchnord-fett';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: 'schulbuchnord-fett';
}
